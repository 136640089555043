.Breadcrumb {
  > ul {
    @include flex(center);
  }

  li {
    &:not(:last-child) {
      .Link {
        &:after {
          content: ">";
          display: inline-block;
        }
      }
    }

    &:last-child{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 50%;
      width: 100%;

      .Link{
        font-weight: 700;
        pointer-events: none;
      }
    }

    & + li{
      margin-left: 0.25em;
    }
  }

  .Link {
    font: 300 14px $main-font;

    @include responsive($tabletSmall){
      @include font-vw(14);
    }

    .PageHeaderAlternative &,
    .PageHeader.--green &{
      color: $white;

      > span{
        background-image: linear-gradient($white, $white);
      }
    }
  }
}
