.PageHeaderSinglePost{
  padding-top: 12.5em;
  padding-bottom: 5em;
  background: $grey-alt;

  .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column-reverse;
      gap: 2em;
    }
  }

  .container > *{
    width: 100%;
  }

  .container > .wrapper{
    @include flex(flex-start);
    flex-direction: column;

    @include responsive($tabletSmall){
      max-width: space(24);
    }

    > .Link{
      align-items: center;
      flex-direction: row-reverse;
      margin-bottom: 1.2em;

      @include noTouchHover() {
        .Icon.--chevron{
          transform: translate(-0.3em, 0) rotate(90deg);
        }
      }

      .Icon.--chevron{
        transform: rotate(90deg);
        max-width: get-vw(12);
        transition: transform 600ms $ease-in-out-circ 50ms;
      }
    }
  }

  .container > .visual__container{
    @include imgRatio(480, 370);

    @include responsive($tabletSmall){
      max-width: space(16);
      margin-left: auto;
      margin-right: space(1);
    }
  }
}
