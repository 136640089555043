.AccompanimentCard{
  @include flex(center, center);
  flex-direction: column;
  text-align: center;

  @include responsive($tabletSmall, max){
    background: $grey-alt;
    border-radius: 20px;
    padding: 2em;
  }

  > .visual__container{
    @include imgRatio(170, 100);
    width: get-vw(170);
    margin-bottom: 1.8em;

    @include responsive($tabletSmall, max){
      min-width: 150px;
    }

    .visual{
      transform: none;

      img{
        object-fit: contain;
      }
    }
  }

  > span{
    font: 600 20px $main-font;

    @include responsive($tabletSmall){
      @include font-vw(24);
    }
  }

  > p{
    margin-top: 0.85em;
  }
}
