.TestimonialQuote{
  @include flex(center, center);
  flex-direction: column;
  max-width: space(35);
  gap: 2em;
  margin: 0 auto;

  &.--alternative{
    align-items: flex-start;
    max-width: 100%;
    margin: 0;
    border-radius: 20px 0 20px 0;
    background-color: #F5FAFA;
    padding: 3.5em 2em;

    .wswyg--content{
      @include responsive($tabletSmall){
        @include font-vw(20);
      }
    }

    .author{
      .visual__container{
        border-radius: 50%;
        width: get-vw(80);
      }
    }
  }

  &:not(.--alternative) {
    .wswyg--content{
      text-align: center;
    }

    .author{
      border-radius: 30px 0px 30px 0px;
      border: 1px solid $green;

      .visual__container{
        border-radius: 0px 0px 30px 0;
      }
    }
  }

  .wswyg--content{
    font-weight: 300;
  }

  .author{
    @include flex(center, center);
    overflow: hidden;

    .visual__container{
      @include imgRatio(100, 100);
      width: get-vw(100);

      @include responsive($tabletSmall, max){
        min-width: 100px;
      }
    }

    .wrapper{
      @include flex(flex-start);
      flex-direction: column;
      gap: 0.5em;
      padding: 1.5em;

      .name{
        font: 600 16px $main-font;

        @include responsive($tabletSmall){
          @include font-vw(16);
        }
      }

      .position{
        font: 300 14px $main-font;

        @include responsive($tabletSmall){
          @include font-vw(14);
        }
      }
    }
  }
}
