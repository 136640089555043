@font-face {
  font-family: codec;
  font-weight: 300;
  font-display: block;
  src: url('../fonts/CodecPro-Light.woff2') format("woff2"), url('../fonts/CodecPro-Light.woff') format("woff");
}

@font-face {
  font-family: codec;
  font-weight: 300;
  font-display: block;
  font-style: italic;
  src: url('../fonts/CodecPro-LightItalic.woff2') format("woff2"), url('../fonts/CodecPro-LightItalic.woff') format("woff");
}

@font-face {
  font-family: codec;
  font-weight: normal;
  font-display: block;
  src: url('../fonts/CodecPro-Regular.woff2') format("woff2"), url('../fonts/CodecPro-Regular.woff') format("woff");
}

@font-face {
  font-family: codec;
  font-weight: 600;
  font-display: block;
  src: url('../fonts/CodecPro-Bold.woff2') format("woff2"), url('../fonts/CodecPro-Bold.woff') format("woff");
}

@font-face {
  font-family: codec;
  font-weight: 600;
  font-display: block;
  font-style: italic;
  src: url('../fonts/CodecPro-BoldItalic.woff2') format("woff2"), url('../fonts/CodecPro-BoldItalic.woff') format("woff");
}

@font-face {
  font-family: codec;
  font-weight: 700;
  font-display: block;
  src: url('../fonts/CodecPro-ExtraBold.woff2') format("woff2"), url('../fonts/CodecPro-ExtraBold.woff') format("woff");
}

@font-face {
  font-family: codec;
  font-weight: 700;
  font-display: block;
  font-style: italic;
  src: url('../fonts/CodecPro-ExtraBoldItalic.woff2') format("woff2"), url('../fonts/CodecPro-ExtraBoldItalic.woff') format("woff");
}