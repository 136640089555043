.BannerEstimation{
  z-index: 2;

  &:last-child{
    padding-bottom: 0;
  }

  &:after{
    top: 100%; left: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='102' height='102' viewBox='0 0 102 102' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M102 0C45.667 0 0 45.667 0 102V0H102Z' fill='%2313BFD1'/%3E%3C/svg%3E%0A");
  }

  &.--before-faq{
    &.--clip-fake{
      .container{
        padding-bottom: get-vw(50);
      }
    }
  }

  .Gutenberg &{
    &:after{
      content: none;
    }

    &:last-child{
      margin-bottom: -10em;
    }
  }

  .container{
    background: $blue;
  }

  .container > .wrapper{
    @include flex(center, center);
    flex-direction: column;
    min-height: 20em;
    position: relative;
    z-index: 1;
    text-align: center;
    color: $white;

    h2{
      margin-bottom: 0;

      @include responsive($tabletSmall){
        max-width: space(38);
        margin-left: auto;
        margin-right: auto;
      }

      br{
        @include responsive($tabletSmall, max){
          display: none;
        }
      }
    }

    p{
      margin-top: 1em;
    }

    .Btn{
      margin-top: 2em;
    }
  }

  .Icon.--watermark{
    position: absolute;
    top: 0; left: 50%;
    transform: translate(-50%, 10%);
    z-index: 0;
    opacity: 0.2;

    @include responsive($tabletSmall){
      width: get-vw(1060);
    }

    svg > *{
      stroke: $green;
    }
  }
}
