.FooterNavSub{
  > ul{
    @include flex();
    gap: 0.3em;

    li{
      &:not(:last-child):after {
        content : '-';
        margin-left: 0.3em;
      }

      .Link{
        font: 400 14px $main-font;

        @include responsive($tabletSmall){
          @include font-vw(14);
        }
      }
    }
  }
}
