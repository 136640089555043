.Btn {
  all: unset;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.65em;
  text-align: center;
  background: $blue;
  color: $white;
  border-radius: 20em;
  padding: 1.25em 2em;
  transition: background-color 450ms $Power2EaseInOut;
  box-sizing: border-box;

  @include noTouchHover() {
    background-color: $green;
  }

  > span{
    font: 700 14px $main-font;

    @include responsive($tabletSmall) {
      @include font-vw(16);
    }
  }

  &.--small{
    padding: 0.7em 1em;

    > span{
      @include responsive($tabletSmall) {
        @include font-vw(14);
      }
    }
  }

  &.--green{
    background: $green;
    color: $white;
  }

  &.--with-icon {
    .Icon {
      width: get-vw(18);

      @include responsive($tabletSmall, max){
        min-width: 18px;
      }
    }
  }

  &.--outline {
    border: 1px solid $blue;
    background: none;

    > span {
      color: $blue;
    }

    &.--white {
      border-color: $white;

      > span {
        color: $white;
      }
    }
  }
}

.--fake-btn{
  @include noTouchHover() {
    .Btn{
      background-color: $green;
    }
  }
}