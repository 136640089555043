.BasicContent{
  &.--attribution{
    .LabeledOffer &{
      padding: 0;
    }

    .container > .wrapper{
      flex-direction: column;
      border-radius: 30px;
      background: $grey-l;
      padding: 2.5em;
      
      @include responsive($tabletSmall){
        max-width: space(40);
        margin: 0 auto;
        padding: 2.5em 4em;
      }

      h2{
        color: $blue;

        @include responsive($tabletSmall){
          @include font-vw(32);
        }
      }

      .wswyg--content{
        @include responsive($tabletSmall){
          max-width: none;
          margin-left: 0;
        }
      }
    }
  }

  .container > .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > *{
      width: 100%;
    }

    > h2{
      @include responsive($tabletSmall){
        max-width: space(16);
      }
    }

    > .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(22);
        margin-left: auto;
      }
    }
  }
}
