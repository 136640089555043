.Brand {
  display: block;
  position: relative;
  z-index: 10;
  pointer-events: all;
  margin-right: auto;
  overflow:hidden;
  @include imgRatio(102, 88);
  min-width: 80px;
  max-width: 80px;

  @include responsive($tabletSmall) {
    min-width: get-vw(100);
    max-width: get-vw(100);
  }

  .Header &{
    @include responsive($menuBreakpoint){
      min-width: get-vw(85);
      max-width: get-vw(85);
    }
  }

  svg {
    @include cover();
  }

  svg > * {
    body.--show-menu & {
      @include responsive($menuBreakpoint, max) {
        fill: $white;
      }
    }
  }

  .Intro & {
    margin-left: auto;

    svg > * {
      fill: $white;
    }
  }
}