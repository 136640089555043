.WarrantyTable{
  @include flex();
  margin-top: 8em;
  
  @include responsive($tabletSmall, max){
    margin-top: 12em;
  }

  &:not(.--active){
    display: none;
  }

  .wswyg--content{
    font-size: 14px;
    padding: 1.25em;
    width: 100%;
  }

  aside,
  .column{
    width: 100%;

    @include responsive($tabletSmall){
      // max-width: space(8.395);
      max-width: space(8.3);
    }
  }

  aside{
    position: relative;
    z-index: 2;
    background: $white;

    @include responsive($tabletSmall, max){
      display: none;
    }

    @include responsive($tabletSmall){
      @include flex(flex-start);
      flex-direction: column;
      border: 1px solid rgba(#214C3E, 0.4);
      width: 100%;
    }

    > *{
      &:not(:last-child){
        border-bottom: 1px solid rgba(#214C3E, 0.4);
      }
    }
  }

  .swiper{
    width: 100%;

    @include responsive($tabletSmall){
      margin-left: 15px;
      margin-right: 0;
      min-height: 100%;
      max-width: space(35);
    }

    .swiper-slide:not(.swiper-slide-active){
      // outline: 3px solid red;
      transform: scale(0.7);
      opacity: 0;
    }

    .swiper-slide-active ~ .swiper-slide{
      // outline: 3px solid green;
      transform: scale(1);
      opacity: 1;
    }

    .swiper-slide.column{
      @include flex(flex-start);
      flex-direction: column;
      height: 100%;
      position: relative;

      @include responsive($tabletSmall, max){
        max-width: 100%;
      }

      &:not(:last-child){
        margin-right: 15px;
      }

      > .head{
        font: 600 24px $main-font;
        color: $white;
        text-align: center;
        border-radius: 30px 30px 0 0;
        padding: 1.2em 0.25em 1em 0.25em;

        position: absolute;
        bottom: 100%; left: 0;
        width: 100%;

        @include responsive($tabletSmall){
          @include font-vw(24);
        }
      }

      > .cells{
        @include flex(flex-start);
        flex-direction: column;
        border: 1px solid rgba(#214C3E, 0.4);
        border-top: none;
        height: 100%;
        width: 100%;
        border-radius: 0 0 30px 30px;
      
        .cell{        
          position: relative;

          &, > *{
            width: 100%;
          }

          &:not(:last-child){
            border-bottom: 1px solid rgba(#214C3E, 0.4);
          }

          .cell-headline{
            @include responsive($tabletSmall){
              opacity: 0;
              visibility: hidden;
            }
          }

          .cell-content{
            @include responsive($tabletSmall){
              position: absolute;
              top: 0; left: 0;
            }
          }
        }
      }
    }

    .swiper-navigation{
      position: absolute;
      
      @include responsive($tabletSmall, max){
        bottom: 110%;
        left: 50%;
        transform: translate(-50%, 0);
      }
      
      @include responsive($tabletSmall){
        top: -7em;
        right: 0;
      }
    }
  }
}
