
.Icon {
  @include imgRatio(1, 1);
  position: relative;

  svg {
    @include cover();
  }

  &.--arrow{
    @include imgRatio(24, 18);
    width: get-vw(24);

    @include responsive($tabletSmall, min){
      min-width: 24px;
    }

    svg > *{
      fill: $white;
    }
  }

  &.--watermark{
    @include imgRatio(1027, 716);
    width: get-vw(1027);
  }

  &.--watermark-simple{
    @include imgRatio(720, 526);
    width: get-vw(526);

    @include responsive($tabletSmall, max){
      min-width: 100%;
    }

    svg > *{
      stroke: $white;
    }
  }

  &.--facebook,
  &.--twitter,
  &.--linkedin,
  &.--youtube{
    .Socials &{
      width: get-vw(20);

      @include responsive($tabletSmall, max){
        min-width: 20px;
      }
    }

    svg > *{
      fill: $blue;

      .Socials &{
        fill: $white;
      }
    }
  }

  &.--facebook{
    @include imgRatio(31, 31);
    width: get-vw(31);

    @include responsive($tabletSmall, max){
      min-width: 31px;
    }
  }

  &.--twitter{
    @include imgRatio(31, 26);
    width: get-vw(31);

    @include responsive($tabletSmall, max){
      min-width: 31px;
    }
  }

  &.--linkedin{
    @include imgRatio(27, 27);
    width: get-vw(27);

    @include responsive($tabletSmall, max){
      min-width: 27px;
    }
  }

  &.--youtube{
    @include imgRatio(20, 14);
    width: get-vw(20);

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }
  }

  &.--chevron{
    @include imgRatio(20, 11);
    width: get-vw(20);

    @include responsive($tabletSmall, max){
      min-width: 15px;
    }

    svg > *{
      stroke: #214C3E;
    }
  }

  &.--chevron-alt{
    @include imgRatio(7, 12);
    width: get-vw(7);

    @include responsive($tabletSmall, max){
      min-width: 7px;
    }

    svg > *{
      stroke: $blue;
    }
  }

  &.--search{
    @include imgRatio(22, 22);
    width: get-vw(22);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    svg > *{
      fill: $blue;
    }
  }

  &.--plus{
    @include imgRatio(17, 17);
    width: get-vw(17);

    @include responsive($tabletSmall, max){
      min-width: 17px;
    }

    svg > *{
      fill: $blue;
    }
  }

  &.--quote{
    @include imgRatio(47, 35);
    width: get-vw(47);

    @include responsive($tabletSmall, max){
      min-width: 35px
    }

    svg > *{
      fill: $green;
    }
  }

  &.--close{
    @include imgRatio(20, 20);
    width: get-vw(20);

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }

    svg > *{
      fill: #1A4244;
    }
  }

  &.--formula{
    @include imgRatio(91, 80);
    width: get-vw(80);

    @include responsive($tabletSmall, max){
      min-width: 50px;
    }
    
    svg > *{
      fill: $white;
    }
  }

  &.--download{
    @include imgRatio(22, 22);
    width: get-vw(22);
    transition: transform 600ms $ease-in-out-circ;

    @include responsive($tabletSmall, max){
      min-width: 22px;
    }

    svg > *{
      fill: $blue;
    }
  }

  &.--simulator-macaron{
    @include imgRatio(190, 190);
    width: get-vw(190);

    @include responsive($tabletSmall, max){
      min-width: 190px;
    }
  }

  &.--arrow-alt{
    @include imgRatio(17, 16);
    width: get-vw(17);

    @include responsive($tabletSmall, max){
      min-width: 17px;
    }

    svg > *{
      stroke: $green;
      stroke-width: 1.5;
    }
  }
}
