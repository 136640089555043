.RelatedPosts{
  position: relative;
  background: $grey-alt;
  overflow: hidden;

  .Home &{
    background: $white;
  }

  .swiper{
    &-slide{
      width: 100%;
      max-width: 80%;
      
      @include responsive($tabletSmall){
        max-width: get-vw(430);
      }
    }

    &-navigation{
      @include responsive($tabletSmall, max){
        order: 2;
        justify-content: flex-start;
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        position: absolute;
        bottom: 100%; right: 0;
        transform: translate(0, -2em);
      }
    }
  }
}
