.NavToggle {
  @include flex(center, center);
  flex-direction: column;
  gap: 0.3em;
  position: relative;
  background: 0;
  border: 0;
  outline: 0;
  padding: 1em;
  z-index: 10;
  pointer-events: all;
  cursor: pointer;

  @include responsive($menuBreakpoint) {
    display: none;
  }

  &:before{
    @include content();
    position: absolute;
    height: 2em;
    width: 2em;
    border: 1px solid $white;
    opacity: 0;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: opacity 400ms $ease-out-quad;

    body.--show-menu &{
      opacity: 1;
    }
  }

  .line {
    display: block;
    height: 2px;
    width: 20px;
    background: $white;
    border-radius: 6px;
    transition: transform 400ms $ease-out-quad;

    &:first-child{
      body.--show-menu &{
        transform: translate(0, 0.47em) rotate(45deg);
      }
    }

    &:nth-child(2){
      body.--show-menu &{
        opacity: 0;
      }
    }

    &:nth-child(3){
      body.--show-menu &{
        transform: translate(0, -0.4em) rotate(-45deg);
      }
    }
  }
}
