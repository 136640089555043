.WarrantyTables{
  overflow: hidden;

  .container > h2{
    margin-top: 1.5em;
    margin-bottom: 0;
    text-align: center;

    @include responsive($tabletSmall){
      display: none;
    }
  }

  .container > nav{
    position: relative;
    z-index: 10;

    &.--show-items {
      > .dropdown-button{
        .Icon.--chevron{
          transform: scaleY(-1);
        }
      }

      > ul {
        @include responsive($tabletSmall, max){
          clip-path: inset(0% 0% 0% 0%);
        }
      }
    }

    > .dropdown-button {
      @include responsive($tabletSmall, max){
        @include flex(flex-start, center);
        font: 600 16px $main-font;
        background: $blue;
        color: $white;
        padding: 1em;
        border-radius: 4px;
        border: 1px solid rgba(#214C3E, 0.4);
        width: 100%;
        position: relative;
        z-index: 1;
      }

      @include responsive($tabletSmall){
        display: none;
      }

      .current {
        flex: 1;
        padding-right: 1em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .Icon.--chevron{
        min-width: 10px;
        margin-top: 0.15em;
        transition: transform 550ms $ease-in-out-circ;
        
        @include responsive($tabletSmall, max){
          min-width: 14px;
        }

        svg > *{
          stroke: $white;
          stroke-width: 2px;
        }
      }
    }

    > ul{
      @include flex(flex-start, space-between);

      @include responsive($tabletSmall, max){
        position: absolute;
        top: 95%; left: 0;
        width: 100%;
        flex-direction: column;
        padding: 1em;
        background: $grey-alt;
        border-radius: 0 0 4px 4px;
        z-index: 0;
        clip-path: inset(0% 0% 100% 0%);
        transition: clip-path 650ms $ease;
      }

      @include responsive($tabletSmall){
        background: $grey-alt;
        border: 1px solid rgba(#214C3E, 0.4);
        border-radius: 20em;
        padding: 0.25em;
      }

      li {
        &, button{
          @include responsive($tabletSmall, max){
            width: 100%;
          }
        }

        button {
          font: 400 16px $main-font;
          transition: color 350ms $ease,
                      background 350ms $ease;

          @include responsive($tabletSmall, max){
            padding: 0.25em 0;
          }
          
          @include responsive($tabletSmall){
            @include font-vw(16);
            text-align: center;
            padding: 1em 2em;
            border-radius: 20em;
            color: $green;
          }

          &.--active{
            font-weight: 600;

            @include responsive($tabletSmall, max){
              color: $blue;
            }

            @include responsive($tabletSmall){
              background: $blue;
              color: $white;
            }
          }
        }
      }
    }
  }

  .container > .infos{
    @include flex(flex-start);
    margin-top: 3em;

    @include responsive($tabletSmall, max){
      gap: 2em;
      flex-direction: column;
    }

    .wrapper{
      @include responsive($tabletSmall){
        max-width: space(31);
      }

      > .wswyg--content{
        font-size: 11px;

        @include responsive($tabletSmall){
          @include font-vw(11);
        }
      }

      > ul{
        @include flex(flex-start);
        margin-top: 1em;
        flex-wrap: wrap;
        gap: 1em;

        li{
          @include flex();
          position: relative;

          &:not(:last-child){
            @include responsive($tabletSmall){
              padding-right: 1em;
            }

            &:after{
              @include responsive($tabletSmall){
                @include content();
                position: absolute;
                top: 50%; left: 100%;
                height: 1em;
                width: 1px;
                background: $blue;
                transform: translate(0, -50%);
              }
            }
          }
        }
      }
    }

    .Btn{
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }
}
