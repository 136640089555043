.Arrow {
  position: relative;
  @include imgRatio(1, 1);
  min-width: 24px;

  svg {
    @include cover();
    fill: transparent;
  }

  &.--default {
    @include imgRatio(24, 17);
    min-width: 24px;

    @include responsive($tabletSmall) {
      min-width: get-vw(24);
    }
  }

  &.--dropdown {
    @include imgRatio(16, 16);
    min-width: 16px;

    @include responsive($tabletSmall) {
      min-width: get-vw(16);
    }
  }
}
