.RelatedProfiles{
  background: $green;
  padding-bottom: get-vw(200);
  overflow: hidden;

  .Gutenberg &{
    &:last-child{
      margin-bottom: -10em;
    }
  }

  h2{
    text-align: center;
    color: $white;

    @include responsive($tabletSmall){
      max-width: space(30);
      margin: 0 auto 0.25em;
    }
  }

  .swiper{
    &-slide{
      max-width: 85%;

      @include responsive($tabletSmall){
        max-width: get-vw(290);
      }
    }

    &-navigation{
      margin-bottom: 1.5em;
    }
  }  
}
