.SimulatorProfileCard{
  @include flex(center, center);
  gap: 1.2em;
  position: relative;
  padding: 1em;
  border-radius: 14px;
  border: 1px solid rgba($green, 0.1);
  transition: transform 500ms $ease;
  
  @include responsive($tabletSmall, max){
    flex-direction: column;
  }

  @include noTouchHover() {
    transform: scale(0.95);
  }

  > .profile-icon{
    height: 24px;
    width: 24px;

    @include responsive($tabletSmall){
      height: get-vw(24);
      width: get-vw(24);
    }

    img{
      width: 100%;
      height: auto;
    }
  }

  > span{
    font-weight: 600;
    flex: 1;
  }

  > .Icon.--arrow-alt{
    transform: scaleX(-1);
    width: get-vw(24);

    @include responsive($tabletSmall, max){
      position: absolute;
      top: 10%;
      right: 5%;
      transform: scale(2) rotate(140deg);
      opacity: 0.1;
      pointer-events: none;
    }
  }
}
