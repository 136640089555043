.NotFound{
  .container{
    @include flex(center, center);
    flex-direction: column;
    height: 100dvh;
    max-height: 800px;

    h1{
      line-height: 0.7;
      margin-bottom: 0.2em;

      @include responsive($tabletSmall){
        @include font-vw(150);
      }
    }

    .Btn{
      margin-top: 1em;
    }
  }
}
