body {
  font-family: $codec;
}

.App {
  width: 100%;
  min-height: 100vh;
  background: $white;
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }

  > * {
    width: 100%;

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  width: 100%;
  padding: 0 30px;
  position: relative;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 24);
  }

  &.--col-4{
    @include responsive($tabletSmall) {
      padding: 0 calc(100vw / 12);
    }
  }
}

section {
  padding: 60px 0;

  @include responsive($tabletSmall) {
    padding: get-vw(100) 0;
  }

  &:last-child{
    padding-bottom: 100px;

    @include responsive($tabletSmall){
      padding-bottom: get-vw(200);
    }
  }
}

.grecaptcha-badge{
  z-index: 10;
}