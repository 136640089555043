.PageHero{
  height: 110dvh;
  min-height: 650px;
  max-height: 800px;
  padding-top: 12.5em;
  padding-bottom: 10em;
  position: relative;

  .Home &{
    max-height: none;
  }

  .container{
    z-index: 1;

    h1{
      color: $white;

      strong{
        color: $blue;
      }
    }
  }

  > .visual__container{
    z-index: 0;
    
    &, &:after{
      @include cover();      
    }

    &:after{
      @include content();
      background: linear-gradient(180deg, rgba($black, 0.4) 0.62%, rgba($white, 0) 95.35%);
    }
  }
}
