.PresentationCards{
  overflow: hidden;

  .container > .head{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > *{
      width: 100%;
    }

    h2{
      @include responsive($tabletSmall){
        max-width: space(18);
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(21);
        margin-left: auto;
        margin-top: 0.5em;
      }
    }
  }

  .container > .swiper{
    margin-top: 2.5em;

    .swiper-wrapper{
      @include responsive($tabletSmall){
        display: grid;
        align-items: start;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: space(2);
      }
    }

    .swiper-slide{
      @include responsive($tabletSmall, max){
        max-width: 80%;
      }
    }
  }
}
