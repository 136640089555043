.Criterias{
  z-index: 3;
  margin-top: get-vw(-100);

  &:after{
    bottom: 100%; right: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 100C55.2285 100 100 55.2285 100 0V100H0Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
  }

  .container{
    background: $white;
  }

  .container > .head,
  .container > .content{
    @include flex(flex-start);

    > *{
      width: 100%;
    }
  }

  .container > .head{
    margin-bottom: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;

    }

    h2{
      @include responsive($tabletSmall){
        max-width: space(18);
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(22);
        margin-left: auto;
      }
    }
  }

  .container > .content{
    @include responsive($tabletSmall, max){
      flex-direction: column-reverse;
      gap: 2em;
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(22);
      }
    }

    .Btn{
      margin-top: 2em;
    }

    .visual__container{
      @include imgRatio(450, 580);

      @include responsive($tabletSmall, max){
        max-width: 380px;
      }

      @include responsive($mobile, max){
        max-width: 80%;
      }

      @include responsive($tabletSmall){
        max-width: space(15);
        margin-left: auto;
        margin-right: space(1); 
      }
    }
  }
}
