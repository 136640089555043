.NavSubmenu{
  @include flex(flex-start);
  flex-direction: column;
  background: $base;

  @include responsive($menuBreakpoint, max){
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }

  @include responsive($menuBreakpoint) {
    position: absolute;
    z-index: 0;
    top: 0; left: 0;
    width: 100%;
    padding: 8.5em space(10) 2.5em;
    clip-path: inset(0% 0% 0% 0%);
    transition: clip-path 650ms $ease;
  }

  // When the parent has not the --open className
  li.--submenu:not(.--open) & {
    @include responsive($menuBreakpoint, max){
      display: none;
    }
    
    @include responsive($menuBreakpoint){
      clip-path: inset(0% 0% 100% 0%);
    }
  }

  // Submenu title
  > span{
    color: $blue;
    margin-bottom: 20px;
    
    @include responsive($menuBreakpoint, max){
      display: none;
    }

    @include responsive($menuBreakpoint) {
      @include font-vw(24);
      margin-bottom: 0.85em;
    }
  }

  > ul{
    @include flex(flex-start);
    flex-direction: column;
    gap: 0.5em;
  }
}
