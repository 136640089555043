.PageHeaderLandingDevis{
  padding-top: 12.5em;
  padding-bottom: 5em;
  position: relative;

  > .container{
    z-index: 1;

    h1{
      color: $white;
      width: 100%;

      @include responsive($tabletSmall){
        max-width: get-vw(425);
        margin-left: space(2);
      }
    }
  }

  > .visual__container{
    z-index: 0;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: linear-gradient(270deg, rgba($black, 0) 0%, rgba($black, 0.6) 100%), 
                  linear-gradient(180deg, rgba($black, 0.8) 0%, rgba($black, 0) 100%);
    }
  }
}
