.Gutenberg{
  padding-top: 12.5em;
  padding-bottom: 10em;

  .container.--main {
    > * + * {
      margin-top: 1.25em;
    }

    > div + div,
    > div + section,
    > div + figure,
    > section + div,
    > section + section,
    > section + figure,
    > figure + div,
    > figure + section,
    > figure + figure{
      margin-top: 5em;
    }

    > div + h2,
    > section + h2,
    > figure + h2,
    > div + h3,
    > section + h3,
    > figure + h3,
    > div + h4,
    > section + h4,
    > figure + h4,
    > div + h5,
    > section + h5,
    > figure + h5,
    > div + h6
    > section + h6,
    > figure + h6{
      margin-top: 3.5em;
    }

    > section:not(.RelatedProfiles):not(.ContentPicture) {
      @include responsive($tabletSmall){
        padding: get-vw(50) 0;
      }
    }

    iframe{
      border-radius: 30px;
      overflow: hidden;
    }

    ul{
        padding-left: 1em;
        list-style: disc;
     }

    ol {
      padding-left: 1.25em;
      list-style-type: decimal;
    }

     ul,
     ol {
        > li + li {
           margin-top: 0.5em;
        }

        code {
           margin-top: 1em;
           margin-bottom: 1em;
        }
     }
  }

  // Custom blocks
  .PageHeader,
  .PageHeaderAlternative,
  .PresentationCards,
  .BannerContent,
  .BannerEstimation,
  .RelatedQuestions,
  .RelatedProfiles,
  .ContentPicture,
  .Accompaniment{
    margin-left: -30px;
    margin-right: -30px;

    @include responsive($tabletSmall){
      margin-left: calc(-100vw / 24);
      margin-right: calc(-100vw / 24);
    }
  }

  .PageHeader,
  .PageHeaderAlternative{
    margin-top: -12.5em;
  }

  section.ContentPicture{
    & + section.ContentPicture{
      margin-top: 0;
    }

    &:nth-child(odd){
      background: none;
    }
  }

  // Basic blocks
  .wp-block-columns{
    gap: 3em;

    @include responsive($tabletSmall){
      // align-items: center !important;
    }
  }

  .wp-block-column{
    .wp-block-image{
      height: 100%;
      width: 100%;
    }
  }

  .wp-block-image{
    border-radius: 30px;
    overflow: hidden;

    &.size-large{
      img{
        width: 100%;
      }
    }

    &,
    > figure{
      img{
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
      }
    }

    > figure{
      margin: 0;
      height: 100%;
      width: 100%;
    }
  }

  .wp-block-embed{
    display: flex;

    &.alignleft{ justify-content: flex-start }

    &.aligncenter{ justify-content: center }

    &.alignright{ justify-content: flex-end }

    .wp-block-embed__wrapper{
      aspect-ratio: 16/9;
      position: relative;
      width: 100%;
      max-width: 700px;

      > iframe{
        @include cover();
      }
    }
  }

  .wp-block-buttons{
    gap: 1em;
  }

  .wp-block-button__link.wp-element-button{
    all: unset;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.65em;
    text-align: center;
    background: $blue;
    color: $white;
    border-radius: 20em;
    padding: 1.25em 2em;
    transition: background-color 450ms $Power2EaseInOut;
    box-sizing: border-box;
    font: 700 12px $main-font;

    @include responsive($tabletSmall) {
      @include font-vw(16);
    }

    @include noTouchHover() {
      background-color: $green;
    }
  }
}
