.Accompaniment{
  overflow: hidden;

  .container{
    text-align: center;

    > h2{
      margin-bottom: 0.5em;
    }

    > p{
      @include responsive($tabletSmall){
        max-width: space(32);
        margin: 0 auto;
      }
    }
  }

  .swiper{
    margin-top: 3.5em;

    &-navigation{
      margin-bottom: 2em;
    }

    &-slide{
      max-width: 70%;

      @include responsive($tabletSmall){
        max-width: get-vw(300);
      }
    }
  }
}
