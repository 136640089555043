@import url("../../node_modules/swiper/swiper-bundle.min.css");

.swiper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;

  // Only for Mobile Swiper
  &.--mobile{
    .swiper-wrapper{
      @include responsive($tabletSmall){
        cursor: initial;
      }
    }

    .swiper-navigation{
      order: 2;
      margin-top: 3em;
      justify-content: center;

      @include responsive($tabletSmall){
        display: none;
      }
    }
  }

  &-wrapper {
    order: 2;
    cursor: grab;

    .swiper-container:not(.swiper-container-free-mode) &{
      transition: transform 600ms $ease!important;
    }

    .swiper-container.--dragging & {
      transition: none!important;
    }
  }

  &-slide {
    height: auto;
    transition: opacity 600ms $ease,
                transform 600ms $ease;

    // .--dragging & {
      // transform: scale(0.95)!important;
    // }
  }

  &-navigation {
    @include flex(center, flex-end);
    order: 1;

    .Icon.--arrow{
      svg > *{
        stroke: $blue;
      }
    }

    &.--white{
      > *{
        border-color: rgba($white, 0.6);
      }
    }

    > * {
      @include flex(center, center);
      height: get-vw(60);
      width: get-vw(60);
      border-radius: 50%;
      border: 1px solid rgba(#1A4244, 0.3);
      transition: opacity 450ms $CircEaseInOut,
                  transform 450ms $CircEaseInOut;

      @include responsive($tabletSmall, max){
        min-height: 50px;
        min-width: 50px;
      }

      &.swiper-button-disabled {
        opacity: 0.35;
        pointer-events: none;
      }

      &.swiper-button-lock{
        display: none;
      }

      &.prev{
        transform: scaleX(-1);

        @include noTouchHover() {
          transform: scaleX(-1) scale(0.9);
        }
      }

      &.next{
        @include noTouchHover() {
          transform: scale(0.9);
        }
      }

      &+* {
        margin-left: 1em;
      }
    }
  }

  &-pagination {
    @include flex(center, center);
    position: relative;
    margin-top: 3em;
    order: 3;

    &-bullet {
      cursor: pointer;
      display: block;
      width: get-vw(8);
      height: get-vw(8);
      border-radius: 50%;
      background: $base;
      transition: opacity 240ms $ease-out-quad;
      background: $blue;
      opacity: 0.2;

      @include responsive($tabletSmall, max){
        min-width: 8px;
        min-height: 8px;
      }

      @include noTouchHover() {
        transform: scale(1.1);
      }

      &:not(:last-child) {
        margin-right: 1em;
      }

      &-active{
        opacity: 1;
      }
    }
  }

  &-scrollbar {
    order: 4;
    position: relative!important;
    left: 0%!important;
    width: 100%!important;
    margin-top: 20px;
    height: 2px!important;
    background: $grey-l;

    .--dark & {
      background: $base-d;
    }

    &-drag {
      position: absolute;
      top: 0px;
      background: $base;
      border-radius: 4px;
      cursor: pointer;

      .--dark & {
        background: $grey-l;
      }
    }
  }
}
