.AllPosts {
  form {
    @include flex(flex-start);
    margin-bottom: 3em;

    @include responsive($tabletSmall, max) {
      flex-direction: column-reverse;
    }

    .Form__group.--search {
      @include responsive($tabletSmall) {
        max-width: get-vw(365);
        margin-left: auto;
      }

      .Form__control {
        border: 1px solid $blue;
        border-radius: 20em;
        padding: 1.5em 4em 1.5em 2em;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }

        &,
        &::placeholder {
          font: 700 16px $main-font;
          opacity: 1;
          color: $blue;

          @include responsive($tabletSmall, max) {
            font-size: 14px;
          }

          @include responsive($tabletSmall) {
            @include font-vw(16);
          }
        }
      }

      .Icon.--search {
        position: absolute;
        top: 50%;
        right: 1.5em;
        transform: translate(0, -50%);
        pointer-events: none;
      }
    }

    .FormTabs {
      @include responsive($tabletSmall, max) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      .tab {
        > span.small {
          @include responsive($tabletSmall, max) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .grid {
    display: grid;
    grid-gap: 3.5em 1em;

    @include responsive($mobile) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive($tabletSmall) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
