.NavLink{
  position: relative;
  z-index: 10;
  display: inline-flex;
  align-items: flex-start;
  gap: 0.5em;
  line-height: 1.4;

  &:not(.--submenu) {
    font: 600 24px $main-font;

    @include responsive($tabletSmall){
      @include font-vw(14);
    }
  }

  &.--submenu{
    font: 400 20px $main-font;
    display: inline;

    @include responsive($tabletSmall){
      @include font-vw(28);
    }

    @include noTouchHover(){
      .Icon.--arrow{
        transform: translate(0.25em, 0) scale(0.75);
      }
    }
  }

  &[data-slug="sante"],
  &[data-slug="prevoyance"] {
    > span{
      color: $blue;
      background-image: linear-gradient($blue, $blue);
    }

    .Icon.--chevron{
      svg > *{
        stroke: $blue;
      }
    }
  }

  > span{
    color: $white;
    background-image: linear-gradient($white, $white);
  }

  .Icon.--chevron{
    width: get-vw(12);
    margin-top: 0.28em;
    transition: transform 550ms $ease-in-out-circ;

    @include responsive($tabletSmall, max){
      min-width: 14px;
    }

    li.--submenu.--open &{
      transform: scaleY(-1);
    }

    svg > *{
      stroke: $white;
      stroke-width: 2px;
    }
  }

  .Icon.--arrow{
    margin-top: 0.15em;
    transition: transform 550ms $ease-in-out-circ;
    display: inline-block;

    @include responsive($menuBreakpoint, max){
      min-width: 15px;
    }
  }
}
