.PostCard{
  @include flex(flex-start);
  flex-direction: column;
  height: 100%;
  background: $white;
  border-radius: 26px;
  box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.12);
  overflow: hidden;

  @include noTouchHover() {
    > .head{
      .visual__container{
        .visual{
          transform: scale(1.05);
        }
      }
    }
  }

  > *{
    width: 100%;
  }

  > .head{
    @include imgRatio(450, 320);
    position: relative;

    .visual__container{
      @include cover();

      .visual{
        transform: none;
        transition: transform 550ms $ease-out-quad;
      }
    }

    .Categories{
      position: absolute;
      top: 0; left: 0;
      padding: 1em 1.5em;
    }
  }

  > .wrapper{
    @include flex(flex-start);
    flex-direction: column;
    padding: 1.25em;
    flex: 1;

    > span{
      font: 700 18px $main-font;
      line-height: 1.25;
      margin: 0.5em 0;

      @include responsive($tabletSmall){
        @include font-vw(24);
      }

      strong{
        font: inherit;
      }

      br{
        display: none;
      }
    }

    > p{
      margin-bottom: 1em;
    }

    > .Btn{
      margin-top: auto;
    }
  }
}
