.SimulatorMacaron{
  height: 190px;
  width: 190px;
  position: relative;

  @include responsive($tabletSmall){
    height: get-vw(190);
    width: get-vw(190);
  }
  
  > .wrapper{
    @include flex(center, center);
    flex-direction: column;
    position: relative;
    z-index: 1;
    color: $white;
    width: 100%;
    height: 100%;

    span.subtitle{
      font: 700 12px $main-font;
      text-transform: uppercase;
      margin-bottom: 0.5em;

      @include responsive($tabletSmall){
        @include font-vw(12);
      }
    }

    span.title,
    span.title-2{
      font-weight: 700;
      letter-spacing: -0.03em;
      line-height: 1;
    }

    span.title{
      font-size: 40px;

      @include responsive($tabletSmall){
        @include font-vw(40);
      }
    }
  
    span.title-2{
      font-size: 24px;

      @include responsive($tabletSmall){
        @include font-vw(24);
      }
    }
  }


  .Icon{
    @include cover();
    z-index: 0;
    pointer-events: none;
    animation: infinite-rotation 10s linear infinite;
  }
}

@keyframes infinite-rotation{
  0% {
    transform: rotate(0deg);
  }

  100%{
    transform: rotate(360deg);
  }
}