.Form {
  background-color: $white;
  margin: 0;
  border-radius: 25px;

  @include responsive($tabletSmall) {
    max-width: space(30);
  }

  h3 {
    margin-bottom: 30px;
    font-weight: 600;
  }

  .--error {
    color: $error-color!important;
  }

  .row {
    @include flex(flex-start, center);
    border-radius: 30px 0px 0px 30px;
    padding: 40px 30px;
    background: $grey;

    @include responsive($tabletSmall) {
      padding: get-vw(50);
    }

    > * {
      width: 100%;

      &.--full {
        @include responsive($tabletSmall) {
          max-width: calc(100% - 30px);
          min-width: calc(100% - 30px);
          margin-right: 30px;
        }
      }
    }

    .bottom {
      p {
        font-weight: 300;
        line-height: 1;

        span {
          position: relative;

          a {
            font-weight: 600;
  
            &::after {
              content: '';
              width: 100%;
              height: 1px;
              background: $grey;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }

      &__confirmation {
        @include flex(center,initial);
        gap: 9px;
        margin-top: 20px;

        @include responsive($tabletSmall) {
          margin-top: get-vw(20);
        }

        p {
          font-size: 16px;
          line-height: 15px;
          font-weight: 300;
      
          @include responsive($tabletSmall) {
            @include font-vw(16);
          }
        }

        abbr {
          color: $blue
        }

        span.--error {
          position: absolute;
          top: 120%;
          font-size: 12px;
        }
      }

      .rgpd {
        margin-top: 30px;
        font-size: 14px;

        @include responsive($tabletSmall) {
          @include font-vw(14);
          margin-top: get-vw(30);
        }
      }
    }

    .flex {
      @include responsive($tabletSmall) {
        @include flex(center,space-between);
        gap: get-vw(35);
      }
    }
  }

  label:not(.Checkbox) {
    display: block;
    font-size: 16px;
    line-height: 15px;
    font-weight: 300;
    margin-bottom: 6px;
    padding: 0;

    @include responsive($tabletSmall) {
      @include font-vw(16);
    }
  }

  &__group {
    position: relative;
    width: 100%;
    margin-bottom: 30px;

    @include responsive($tabletSmall) {
      margin-bottom: get-vw(35);
    }

    abbr {
      color: $blue;
      font-weight: 400;
    }

    span.--error {
      position: absolute;
      top: 100%;
      left: 0;
      font-size: 10px;
      padding-top: 2px;
    }

    &.--select {
      width: 100%;

      &::after {
        content: '';
        background: url('../imgs/dropdown.svg');
        background-size: contain;
        width: 16px;
        height: 16px;
        position: absolute;
        pointer-events: none;
        right: 5%;
        top: 55%;
  
        @include responsive($tabletSmall) {
          width: get-vw(16);
          height: get-vw(16);
        }
      }
    }
  }

  &__control {
    width: 100%;
    min-height: 42px;
    padding: 0 14px;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    padding: 18px 16px;
    background: $white;
    border-radius: 8px;
    border: 1.5px solid rgba(33, 76, 62, 0.20);
    line-height: 125%;

    @include responsive($tabletSmall) {
      padding: get-vw(18) get-vw(16);
      @include font-vw(16);
      border-width: get-vw(1.5);
    }

    .--error & {
      border-color: $error-color;
    }

    &::placeholder {
      opacity: 0.4;
    }
  }

  textarea.Form__control {
    max-width: 100%;
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 18px;

    @include responsive($tabletSmall) {
      padding: get-vw(24);
    }
  }

  &__actions {
    @include flex(center,flex-end);
    
    .Btn {
      margin-top: 40px;

      @include responsive($tabletSmall) {
        margin-top: get-vw(40);
      }
    }
  }

  &__output {
    color: $valid-color;

    p:not(:empty) {
      margin-top: 20px;
    }
  }
}

.Radios,
.Checkboxs {
  margin-bottom: -10px;

  @include responsive($tabletSmall) {
    @include flex(center, flex-start, inline-flex);
    margin-bottom: get-vw(-10);
  }

  > * {
    margin-bottom: 10px;

    @include responsive($tabletSmall) {
      margin-right: get-vw(40);
    }
  }
}

.Checkbox {
  position: relative;
  @include flex(flex-start, flex-start, inline-flex);
  display: flex !important;
  flex-wrap: nowrap;

  input {
    @include cover();
    margin: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &:checked {
      & + .Checkbox__custom {
        background: $blue;
        border-color: $blue;

        &:after {
          transform: scale(1);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__custom {
    @include flex(center, center);
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    border: 1.5px solid rgba(33, 76, 62, 0.20);
    border-radius: 2px;
    margin-right: 10px;
    transition: background 240ms $ease-out-quad;

    @include responsive($tabletSmall) {
      min-width: get-vw(18);
      max-width: get-vw(18);
      height: get-vw(18);
      border-radius: get-vw(2);
      margin-right: get-vw(10);
      border-width: get-vw(2);
    }

    .--radio & {
      border-radius: 18px;

      @include responsive($tabletSmall) {
        border-radius: get-vw(18);
      }

      &:after {
        @include content;
        width: 10px;
        height: 10px;
        background: $white;
        border-radius: 20px;
        transform: scale(0);
        transition: transform 240ms $ease-out-quad;

        @include responsive($tabletSmall) {
          width: get-vw(10);
          height: get-vw(10);
          border-radius: get-vw(20);
        }
      }
    }

    .check {
      position: relative;
      width: 66%;

      &:before {
        @include content();
        padding-top: percentage(math.div(25, 33));
      }

      svg {
        overflow: visible;
        @include cover();
        opacity: 0;

        > * {
          fill: transparent;
          stroke: $white;
          stroke-width: 6px;
          stroke-linecap: round;

          @include responsive($tabletSmall) {
            stroke-width: get-vw(6);
          }
        }
      }
    }
  }

  &__label {
    text-transform: none;
    font-size: 13px;
    letter-spacing: 0;
    font-weight: 500;

    @include responsive($tabletSmall) {
      @include font-vw(13);
    }
  }
}
