.NeedHelp{
  position: fixed;
  z-index: 10;
  bottom: 2em; right: 2em;
  transition: opacity 500ms $Power3EaseOut,
  transform 500ms $Power3EaseOut;
  
  @include responsive($tabletSmall){
    width: 100%;
    max-width: get-vw(240);
    bottom: 1em; right: 1em;
  }

  body:not(.--show-need-help) &{    
    pointer-events: none;

    @include responsive($tabletSmall){
      opacity: 0;
      transform: translate(50%, 0);
    }
  }

  // Buttons styles only for the mobile version
  > .wrapper > .Btn.--with-icon,
  > .Btn.--with-icon{
    @include responsive($tabletSmall, max){
      height: 70px; width: 70px;
      border-radius: 50%;
      position: absolute;
      transition: background-color 400ms $Power3EaseOut,
                  transform 400ms $Power3EaseOut,
                  opacity 400ms $Power3EaseOut;
    }

    body:not(.--show-need-help-mobile) &{
      @include responsive($tabletSmall, max){
        opacity: 0;
        transform: scale(0);
        pointer-events: none;
      }
    }

    > span{
      // For A11Y
      @include responsive($tabletSmall, max){
        position: absolute;
        top: -99999px; left: -99999px;
      }
    }

    > .Icon{
      @include imgRatio(40, 40);
      width: get-vw(40);

      @include responsive($tabletSmall, max){
        min-width: 40px;
      }

      @include responsive($tabletSmall){
        display: none;
      }
    }
  }

  > button.toggle-mobile{
    @include flex();
    position: relative;
    height: 60px; width: 60px;
    border-radius: 50%;
    background: $white;
    box-shadow: 0px 2px 15px rgba(171, 171, 171, 0.7);
    transition: transform 450ms $Power3EaseOut;

    body.--show-need-help-mobile &{
      transform: scale(0.75) rotate(-45deg);
    }

    @include responsive($tabletSmall){
      display: none;
    }

    &:before,
    &:after{
      @include content();
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      height: 2px; width: 20px;
      background: $green;
    }

    &:before{
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }

  > .wrapper{
    @include responsive($tabletSmall){
      text-align: center;
      background: $white;
      border-radius: 26px;
      padding: 1em;
      box-shadow: 4px 3px 11px rgba(8, 131, 196, 0.3);
    }

    > .inner-desktop{
      @include responsive($tabletSmall, max){
        display: none;
      }

      @include responsive($tabletSmall){
        @include flex(center, center);
        flex-direction: column;
      }

      > button.close-popup-desktop{
        position: absolute;
        top: 0.75em; right: 0.75em;
      }

      > .visual__container{
        @include imgRatio(100, 100);
        max-width: get-vw(100);
        border-radius: 50%;

        @include responsive($tabletSmall, max){
          min-width: 100px;
        }
      }

      > span{
        font-weight: 600;
        margin: 0.5em 0 0;
      }
    }

    > .Btn{
      body.--show-need-help-mobile &{
        @include responsive($tabletSmall, max){
          transition-delay: 100ms;        
        }
      }

      @include responsive($tabletSmall, max){
        bottom: 120%; right: 0;
      }

      @include responsive($tabletSmall){
        margin-top: 0.5em;
        padding: 1em;
        width: 100%;
      }
    }
  }

  > .Btn{
    @include responsive($tabletSmall, max){
      bottom: 0; right: 120%;
    }

    @include responsive($tabletSmall){
      margin-top: 1em;
      width: 100%;
      box-shadow: 3px 3px 11px -1px rgba(8, 131, 196, 0.3);
    }
  }
}
