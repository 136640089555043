$menuBreakpoint: $tabletSmall;

.Header {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;
  background: $base;
  color: $white;

  &.--light{
    background: none;

    body.--scrolled &{
      &.--visible{
        background: $base;
      }
    }
  }

  body:not(.--animating) &{
    transition: background 400ms $Power2EaseOut, 
                transform 700ms $Power2EaseOut;
  }

  body.--scrolled &{
    &:not(.--visible){
      transform: translate(0, -150%);
    }
  }

  body.--show-menu &{
    &:after{
      @include responsive($menuBreakpoint, max) {
        @include content();
        @include cover();
        background: linear-gradient(180deg, rgba($base,1) 20%, rgba($base,0) 100%);
      }
    }
  }

  .container {
    width: 100%;
    max-width: none;
    padding-top: 20px;
    padding-bottom: 20px;

    @include responsive($menuBreakpoint) {
      padding-top: get-vw(60);
      padding-bottom: get-vw(30);
      padding-left: calc(100vw / 48);
      padding-right: calc(100vw / 48);
    }

    .wrapper {
      // position: relative;
      @include flex(center, flex-end);
    }
  }
}
