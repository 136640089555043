.PresentationTabs{
  .container > .head{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > *{
      width: 100%;
    }

    h2{
      @include responsive($tabletSmall){
        max-width: space(20);
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(21);
        margin-left: auto;
      }
    }
  }

  .container > .content{
    margin-top: 3.5em;

    > span{
      font: 600 20px $main-font;
      margin-bottom: 2em;

      @include responsive($tabletSmall){
        @include font-vw(24);
      }
    }

    .tabs{
      @include flex(flex-start);

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }

      > *{
        width: 100%;
      }

      > nav{
        @include responsive($tabletSmall){
          margin-left: space(2);
          max-width: space(14);
        }

        .OfferDetails &{
          @include responsive($tabletSmall){
            max-width: space(17);
          }
        }

        ul{
          @include flex(flex-start);
          flex-direction: column;
          position: relative;
          gap: 2em;

          // Blue vertical line
          &:before{
            @include content();
            position: absolute;
            top: 50%; left: 0.6em;
            height: 95%;width: 1px;
            transform: translate(0, -50%);
            background: rgba($blue, 0.2);

            @include responsive($tabletSmall, max){
              left: 0.35em;
              height: 90%;
            }
          }
        }

        button{
          position: relative;
          padding-left: 2.5em;
        
          @include responsive($tabletSmall){
            padding-left: 4em;
          }

          &.--active{
            &:before{
              transform: translate(0, -50%) scale(3);
            }

            &:after{
              transform: translate(0, -50%) scale(1);
            }

            > span {
              font-size: 1.5em;

              @include responsive($tabletSmall){
                @include font-vw(36);
              }
            }
          }

          &:before,
          &:after{
            @include content();
            height: get-vw(24);
            width: get-vw(24);
            border-radius: 50%;
            position: absolute;
            top: 50%; left: 0;

            @include responsive($tabletSmall, max){
              min-height: 12px;
              min-width: 12px;
            }
          }

          // Big light blue circle
          &:before{
            background: #E7F9FA;
            transform: translate(0, -50%) scale(1);
          }

          // Small blue circle
          &:after{
            background: #13BFD1;
            transform: translate(0, -50%) scale(0);
          }

          > span{
            line-height: 1.1;
          }
        }
      }

      > .wrapper{
        @include responsive($tabletSmall, max){
          margin-top: 2em;
        }

        @include responsive($tabletSmall){
          max-width: space(23);
          margin-left: auto;
        }

        .wswyg--content{
          background: $grey-alt;
          padding: 2em;

          &:not(.--active){
            display: none;
          }
        }
      }
    }
  }
}
