.Close{
  @include flex(center, center);
  position: absolute;
  top: 1em; right: 1em;
  z-index: 10;
  height: get-vw(20);
  width: get-vw(20);
  transition: transform 450ms $ease;

  @include responsive($tabletSmall, max){
    min-height: 20px;
    min-width: 20px;
  }

  @include noTouchHover() {
    transform: rotate(-180deg) scale(0.85);
  }

  &:before,
  &:after{
    @include content();
    position: absolute;
    height: 100%;
    width: 2px;
    background: $green;
    border-radius: 20em;

    @include responsive($tabletSmall, max){
      background: $white;
    }
  }

  &:before{
    transform: rotate(-45deg);
  }

  &:after{
    transform: rotate(45deg);
  }
}
