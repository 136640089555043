.Socials{
  margin-top: 2em;

  > ul{
    @include flex(center);
    gap: 1em;

    a{
      @include imgRatio(40, 40);
      @include flex(center, center);
      background: var(--white);
      position: relative;

      > .Icon{
        pointer-events: none;
      }
    }
  }
}
