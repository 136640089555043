.Pagination{
  @include flex(center, center);
  margin-top: 2.5em;
  gap: 0.5em;

  @include responsive($tabletSmall){
    margin-top: 5em;
  }

  li {
    &.--active{
      a{
        font-weight: 700;
        background: $blue;
        color: $white;
      }
    }

    &:not(.--active){
      a{
        @include noTouchHover() {
          // background: $purple-l4;
        }
      }
    }

    a{
      font: 400 14px $main-font;
      padding: 1em 1.25em;
      border-radius: 4px;
      color: #222A38;
      transition: background 350ms $SineEaseOut;

      @include responsive($tabletSmall){
        @include font-vw(14);
      }

      @include noTouchHover() {
        text-decoration: none;
      }
    }

    > *{
      font: inherit;
    }
  }
}
