.OurStoryTimeline{
  background: $grey-alt;

  h2{
    text-align: center;
    margin-bottom: 2em;
    
    @include responsive($tabletSmall){
      max-width: get-vw(650);
      margin-left: auto;
      margin-right: auto;
    }
  }
}
