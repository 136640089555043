.Page__content{
  padding-top: 0;

  .wswyg--content{
    filter: opacity(0.7);

    @include responsive($tabletSmall){
      max-width: space(20);
    }

    .Home &{
      @include responsive($tabletSmall){
        max-width: space(8);
        margin-left: auto;
      }
    }
  }
}