.OurStoryStep{
  @include flex(center, center);

  @include responsive($tabletSmall, max){
    gap: 2em;
    flex-direction: column;
  }

  @include responsive($tabletSmall){
    padding: 0 space(3);
  }

  &:nth-child(odd){
    > .wrapper{
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }
  
  &:nth-child(even){
    @include responsive($tabletSmall){
      flex-direction: row-reverse;
    }

    > .visual__container{
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }

  & + .OurStoryStep{
    margin-top: 3em;
    
    @include responsive($tabletSmall, max){
      padding-top: 3em;
      border-top: 1px solid #dadada;
    }
  }

  > *{
    width: 100%;
  }

  > .visual__container{
    @include imgRatio(480, 580);

    @include responsive($tabletSmall){
      max-width: space(16);
    }
  }

  > .wrapper{
    @include responsive($tabletSmall){
      max-width: space(17);
    }

    h3{
      font: 300 30px $main-font;
       margin-bottom: 1em;

       @include responsive($tabletSmall){
         @include font-vw(48);
       }
    }

    .Btn{
      margin-top: 2em;
    }
  }
}
