.PageHeaderAlternative{
  padding-top: 12.5em;
  padding-bottom: 3.5em;
  background: $green;
  color: $white;
  overflow: hidden;

  .container {
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column-reverse;
      gap: 2em;
    }

    > *{
      width: 100%;
    }
  }

  .container > .head{
    @include responsive($tabletSmall){
      max-width: space(18);
    }

    h1{
      margin-top: 0.25em;
    }
  }

  .container > .visual-wrapper{
    position: relative;

    @include responsive($tabletSmall){
      max-width: space(24);
      margin-left: auto;
    }

    .visual__container{
      @include imgRatio(680, 420);
      z-index: 1;
    }

    .Icon.--watermark{
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-53%, -57%);
      z-index: 0;
      opacity: 0.6;

      @include responsive($tabletSmall, max){
        width: 110%;
      }

      @include responsive($tabletSmall){
        max-width: get-vw(900);
      }
    }
  }
}
