.Formulas{
  background: $grey-alt;
  overflow: hidden;

  &.--novaio{
    .OfferDetails[data-active-offer="2"] &{
      display: none;
    }
  }

  &.--moderato{
    .OfferDetails[data-active-offer="1"] &{
      display: none;
    }
  }

  > .container{
    @include flex(center, center);
    flex-direction: column;
  }
  
  .container > .head{
    text-align: center;

    @include responsive($tabletSmall){
      max-width: space(36);
      margin: 0 auto;
    }
  }

  .container > .swiper{
    width: 100%;
    margin-top: 2em;

    .swiper-wrapper{
      @include responsive($tabletSmall){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em;
      }
    }

    .swiper-slide{
      @include responsive($tabletSmall, max){
        max-width: 70%;
      }
    }
  }

  .container > .Btn{
    margin-top: 3em;
  }
}
