.BannerContent{
  position: relative;

  .BannerEstimation + & {
    padding-top: get-vw(200);
    margin-top: get-vw(-100);
  }

  .Gutenberg &{
    background: $grey-l;
    clip-path: inset(0em 0em 0em round 2.5em 0em 2.5em);

    @include responsive($tabletSmall){
      clip-path: inset(0em 0em 0em round 5em 0em 5em);
    }

    h2, p{
      color: $green;
    }
  }

  > .container{
    @include flex(center, center);
    flex-direction: column;
    min-height: 20em;
    z-index: 1;
    text-align: center;
    color: $white;

    h2{
      margin-bottom: 0;

      @include responsive($tabletSmall){
        max-width: space(38);
        margin-left: auto;
        margin-right: auto;
      }

      br{
        @include responsive($tabletSmall, max){
          display: none;
        }
      }
    }

    p{
      margin-top: 1em;
    }

    .Btn{
      margin-top: 2em;
    }
  }

  > .visual__container{
    z-index: 0;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: rgba($black, 0.2);
    }
  }
}
