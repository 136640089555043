.Popup{
  @include cover(fixed);
  @include flex(center, center);
  z-index: 1001;
  background: rgba($black, 0.7);

  body:not(.--show-popup) &{
    display: none;
  }
  
  .inner{
    @include flex(flex-start);
    background: $white;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include responsive($tabletSmall, max){
      flex-direction: column;
      max-width: 80%;
    }

    @include responsive($tabletSmall){
      max-width: space(27);
    }

    > .visual__container,
    > .wrapper{
      width: 100%;      
    }

    > .visual__container{
      @include imgRatio(400, 250);
      background: $grey-l;
      
      @include responsive($tabletSmall){
        @include imgRatio(400, 580);
        max-width: 40%;
      }

      &:after{
        @include responsive($tabletSmall, max){
          @include content();
          @include cover();
          background: rgba($black, 0.25);
        }
      }
    }
  
    > .wrapper{
      max-height: 23.5em;
      overflow-y: scroll;
      padding: 2em;

      @include responsive($tabletSmall){
        max-width: 60%;
        padding-top: 5em;
      }

      h2{
        margin-bottom: 0.5em;

        @include responsive($tabletSmall){
          @include font-vw(34);
        }
      }

      .wswyg--content{
        font-size: 14px;
        
        @include responsive($tabletSmall){
          @include font-vw(14);
        }
      }

      .Btn{
        margin-top: 1.5em;
      }
    }
  }
}