.AllQuestions{
  position: relative;

  .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  .container > * {
    width: 100%;
  }

  .container > .FormTabs{
    @include responsive($tabletSmall, max){
      margin-bottom: 3.5em;
    }

    @include responsive($tabletSmall){
      max-width: space(10);
      position: sticky;
      top: 2em;
    }
  }

  .container > .questions{
    @include flex(flex-start);
    flex-direction: column;
    gap: 1em;

    @include responsive($tabletSmall){
      max-width: space(28);
      margin-left: auto;
    }
  }
}
