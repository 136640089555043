.ContentPictureProfile{
  position: relative;
  z-index: 1;

  .container > .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > *{
      width: 100%;
    }

    h2{
      @include responsive($tabletSmall){
        max-width: space(20);
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(22);
        margin-left: auto;
      }
    }
  }

  .container > .visual__container{
    @include imgRatio(1140, 480);
    margin-top: 3.5em;

    @include responsive($tabletSmall){
      max-width: space(40);
      margin: 5em auto 0;
    }
  }
}
