.ContentPicture{
  background: $grey-l;

  &.--white{
    background: $white;
  }

  // [Picture] | [Content]
  &:not(.--inverted){
    .container > .wrapper{
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }

  // [Content] | [Picture]
  &.--inverted{
    .container{
      @include responsive($tabletSmall){
        flex-direction: row-reverse;
      }
    }

    .container > .visual__container{
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }

  .container {
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
      gap: 2em;
    }
  }

  .container > *{
    width: 100%;
  }

  .container > .visual__container{
    @include imgRatio(480, 600);

    @include responsive($tabletSmall, max){
      max-width: 380px;
    }

    @include responsive($mobile, max){
      max-width: 80%;
    }

    @include responsive($tabletSmall){
      max-width: space(16);
    }
  }

  .container > .wrapper{
    @include responsive($tabletSmall){
      max-width: space(20);
    }

    h2{
      margin-bottom: 0.5em;
    }

    .Btn{
      margin-top: 2em;
    }
  }
}
