.ContactForm {
    padding-top: 8.5em;

    @include responsive($tabletSmall) {
        padding-top: 12.5em;
    }
    
    > .container {
        @include responsive($tabletSmall) {
            padding: 0;
            gap: get-vw(40);
            @include flex(flex-start, space-between);
        }
    }

    &__text {
        flex-basis: 50%;

        @include responsive($tabletSmall) {
            padding: 0 get-vw(80);
        }

        h2 {
            font-weight: 600;
            margin: 50px 0 10px;
            font-size: 24px;

            @include responsive($tabletSmall) {
                margin: get-vw(50) 0 get-vw(10);
                @include font-vw(24);
            }
        }

        h3 {
            font-weight: 600;
            margin: 10px 0 0;
            font-size: 20px;

            @include responsive($tabletSmall) {
                margin: get-vw(10) 0 0;
                @include font-vw(20);
            }
        }

        .subtitle {
            font-size: 15px;
            filter: none;
            margin-bottom: 15px;

            @include responsive($tabletSmall) {
              @include font-vw(15);
              margin-bottom: get-vw(15);
            }
        }

        .columns {
            margin-bottom: 30px;

            @include responsive($mobile) {
                @include flex(initial,space-between);
                gap: 15px;
            }

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(30);
            }

            .column {
                flex-basis: 45%;

                img {
                    width: 100%;
                    object-fit: cover;
                    margin-bottom: 15px;

                    @include responsive($mobile) {
                        min-height: 140px;
                        height: get-vw(140);
                    }
                }
            }
        }
    }

    .Form {
        flex-basis: 50%;
        padding-top: 0;

        @include responsive($tabletSmall, max) {
          margin: 40px -30px 0px -30px;
        }

        h3 {
            font-size: 24px;

            @include responsive($tabletSmall) {
              @include font-vw(24);
            }

            &:not(:first-child) {
                margin: 30px 0 35px;
    
                @include responsive($tabletSmall) {
                    margin: get-vw(30) 0 get-vw(35);
                }
            }
        }

        .--subject {
            margin-bottom: 0;
        }

        .subject {
            font-size: 14px;
            margin: 10px 0 30px;
            display: block;

            @include responsive($tabletSmall) {
                @include font-vw(14);
                margin-bottom: get-vw(10) 0 get-vw(35);
            }
        }

        .Checkbox {
            margin-top: 15px;

            abbr {
                color: $blue
            }
        }
    }
}