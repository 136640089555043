/* -----
Misc
----- */

.--hidden {
  display: none !important;
}

.--centered {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.--left {
  margin-right: auto;
  text-align: left;
}

.--right{
  margin-left: auto;
  text-align: right;
}

.--sr-only {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    left: -9999px;
    top: -9999px;
}

.--no-br br {
  content: '';
  width: 9px;
  height: 18px;
  &:before {
    content: ' '
  }
}

.--mobile-swiper{
  @include responsive($tabletSmall){
    .swiper-wrapper{
      transform: none !important;
      cursor: initial !important;
    }

    .swiper-slide{
      margin: 0 !important;
    }
  }
}

.--no-sb{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
}

.--oh{
  overflow: hidden;
}

.--pr{
  position: relative;
}

/* -----
Lazyload
----- */

html:not(.no-js) .lazyload,
html:not(.no-js) .lazyloading {
  opacity: 0;
}

html.no-js img.sr-only {
  display:block;
  top:0; left:0;
  width:100%;
  height:100%;
  clip:inherit;
  object-fit:cover;
}

.lazyloaded {
  position: relative;
  opacity: 1;
  transition: opacity 400ms linear 400ms;
}

/* -----
Prllx
----- */

*[data-prllxfrom] {
  will-change:transform;

  &[data-mobile-fix] {
    @include responsive($tabletSmall, max) {
      transform: none!important;
    }
  }
}


.--br-30{
  border-radius: 30px;
}

.--clip-top-left{
  clip-path: inset(0em 0em 0em round 2.5em 0em 0em);

  @include responsive($tabletSmall){
    clip-path: inset(0em 0em 0em round 5em 0em 0em);
  }
}

.--clip-top-left-bottom-right{
  clip-path: inset(0em 0em 0em round 2.5em 0em 2.5em);

  @include responsive($tabletSmall){
    clip-path: inset(0em 0em 0em round 5em 0em 5em);
  }
}

// To create little rounded triangle at top/bottom (inverse of clip-path)
.--clip-fake{
  position: relative;
  padding: 0;

  &:after{
    @include content();
    position: absolute;
    height: get-vw(102);
    width: get-vw(102);
    transform: scale(1.01);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 10;
  }

  > .container{
    overflow: hidden;

    &, &.--col-4{
      padding-top: get-vw(100);
      padding-bottom: get-vw(100);
    }
  }


}