.SimulationContent{
  padding-top: 12.5em;
  overflow: hidden;

  h1, 
  .swiper-slide,
  .wswyg--content{
    text-align: center;
  }

  .swiper{
    &-slide{
      @include flex(center, center);
      flex-direction: column;
      background: $grey-alt;
      padding: 3.5em;

      @include responsive($tabletSmall){
        max-width: 45%;
      }

      h2{
        @include responsive($tabletSmall){
          @include font-vw(35);
        }
      }
    }

    &-navigation{
      margin-bottom: 1.5em;
    }
  }

  .wswyg--content{
    margin-top: 2em;
    color: #5B6472;
    
    @include responsive($tabletSmall){
      max-width: space(36);
      margin-left: auto;
      margin-right: auto;
    }
  }
}