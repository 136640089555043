.ParrainageInstructions {
    padding: 0 20px 60px;

    @include responsive($tabletSmall) {
        padding: get-vw(30) get-vw(80) get-vw(120);
    }

    h2 {
        line-height: 1;
        font-weight: 600;
        font-size: 36px;

        @include responsive($tabletSmall) {
            @include font-vw(36);
        }
    }
    
    .flex {            
        @include responsive($tabletSmall) {
            @include flex(initial,space-between);
        }

        .column {
            flex-basis: 17%;
            margin-bottom: 25px;

            .icon {
                @include flex(center,center);
                border: 1.5px solid $blue;
                border-radius: 50%;
                margin-bottom: 15px;
                width: 60px;
                height: 60px;
                padding: 15px;

                @include responsive($tabletSmall) {
                    width: get-vw(130);
                    height: get-vw(130);
                    padding: get-vw(25);
                    margin-bottom: get-vw(35);
                }
            }

            h3 {
                font-size: 24px;
                font-weight: 600;
                line-height: 125%;
                margin-bottom: 10px;

                @include responsive($tabletSmall) {
                  @include font-vw(24);
                }
            }

            p {
                font-size: 16px;

                @include responsive($tabletSmall) {
                    @include font-vw(16);
                }
            }
        }
    }

    .Link{
        margin-top: 2em;
    }
}