.Testimonials{
  position: relative;
  background: $grey-alt;
  overflow: hidden;

  .Profile .ContentPictureProfile + &{
    margin-top: get-vw(-400);
    padding-top: get-vw(400);
    z-index: 0;
  }

  // Special styles for Landing Devis template
  .LandingDevis &{
    background-color: $white;
  }

  .container > h2{
    @include responsive($tabletSmall, max){
      text-align: center;
    }
  }

  .swiper{
    > .Icon.--quote{
      margin: 0 auto 2em;
    }
    
    &-navigation{
      @include responsive($tabletSmall, max){
        display: none;
      }

      @include responsive($tabletSmall){
        position: absolute;
        bottom: 100%; right: 0;
        transform: translate(0, -2em);
      }
    }

    &-pagination{

    }
  }
}
