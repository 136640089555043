.ProfilesOffers{
  background: $green;
  color: $white;
  margin-top: -5em;

  .container > h2{
    @include responsive($tabletSmall){
      max-width: space(30);
    }
  }

  .container > .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column-reverse;
      gap: 2em;
    }

    @include responsive($tabletSmall){
      align-items: center;      
    }

    > *{
      width: 100%;
    }

    .profiles{
      @include flex(flex-start);
      flex-direction: column;

      @include responsive($tabletSmall){
        max-width: space(22);
      }
    }

    .visual__container{
      @include imgRatio(580, 640);

      @include responsive($tabletSmall, max){
        max-width: 380px;
      }

      @include responsive($mobile, max){
        max-width: 80%;
      }
      
      @include responsive($tabletSmall){
        max-width: space(18);
        margin-left: auto;
      }
    }
  }
}
