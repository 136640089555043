.PageHeader{
  padding-top: 12.5em;
  
  &:not(.--green){
    background: $grey-alt;
    padding-bottom: 10em;
  }

  .Gutenberg &,
  &.--green{
    background: $green;
    color: $white;
    padding-bottom: 3.5em;
  }

  .ArchivePosts &{
    padding-bottom: 5em;
  }

  .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  .container > *{
    width: 100%;
  }

  .container > .wrapper{
    @include responsive($tabletSmall){
      max-width: space(22);
    }

    h1{
      margin-top: 0.25em;
    }
  }

  .container > .content{
    @include responsive($tabletSmall){
      max-width: space(20);
      margin-top: 3em;
      margin-left: auto;
      margin-right: space(1);
    }

    .Gutenberg &{
      @include responsive($tabletSmall){
        margin-top: 1.85em;
      }
    }

    > .Link{
      margin-top: 1em;
    }
  }
}
