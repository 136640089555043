.BannerNewsletter{
  background: $blue;
  color: $white;

  .container{
    @include flex(center, center);
    flex-direction: column;
    position: relative;
    z-index: 1;
    text-align: center;
    color: $white;

    h3{
      font: 300 30px $main-font;
       line-height: 1.2;
       margin-bottom: 0;

      @include responsive($tabletSmall){
        @include font-vw(48);
        max-width: space(38);
        margin-left: auto;
        margin-right: auto;
      }

      br{
        @include responsive($tabletSmall, max){
          display: none;
        }
      }
    }

    p{
      margin-top: 1em;

      @include responsive($tabletSmall){
        max-width: space(24);
        margin-left: auto;
        margin-right: auto;;
      }
    }

    .Btn{
      margin-top: 2em;
    }
  }
}
