.Reclamations {
    padding: 0 20px 60px;

    @include responsive($tabletSmall) {
        padding: get-vw(30) get-vw(80) get-vw(120);
    }

    h2 {
        line-height: 1;
        font-weight: 600;
        font-size: 36px;

        @include responsive($tabletSmall) {
            @include font-vw(36);
        }
    }

    .wswyg--content {
        margin-top: 60px;

        @include responsive($tabletSmall) {
            margin-top: get-vw(70);
            column-count: 2;
            column-gap: 40px;
        }

        &:first-child {
            margin-top: 0;
        }
        
        h3 {
            font-weight: 600;
            margin: 10px 0 20px;
            font-size: 20px;

            @include responsive($tabletSmall) {
                margin: get-vw(10) 0 get-vw(20);
                @include font-vw(20);
            }
        }
    }
}