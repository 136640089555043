.PageHeaderOurStory{
  padding-top: 12.5em;
  padding-bottom: 10em;
  overflow: hidden;

  h1{
    text-align: center;
    margin-top: 0.5em;

    @include responsive($tabletSmall, max){
      margin-top: 1.5em;
    }
  }

  .wswyg--content{
    text-align: center;
    padding: 0 space(2);

    ol, ul, li, p{
      @include responsive($tabletSmall){
        @include font-vw(24);
      }
    }
  }

  .video{
    margin: 8em auto 0;
    position: relative;

    @include responsive($tabletSmall){
      max-width: space(28);
    }

    .Icon.--watermark{
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @include responsive($tabletSmall, max){
        width: 110%;
      }
    }
  }
}
