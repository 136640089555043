.Accordion{
  position: relative;

  // Special styles for Landing Devis template
  .LandingDevis &{
    &, 
    &:not(.--profile) .head{
      background-color: $white;
      border-radius: 10px;
    }
  }

  // Cf. Homepage
  &.--profile{
    border-radius: 10px;
    transition: background 450ms $ease;
    width: 100%;
    padding: 1em 0;

    & + .Accordion.--profile{
      &:before{
        @include content();
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 1px;
        background: rgba($white, 0.2);
      }
    }

    &.--active{
      background: rgba($white, 0.05);

      .Icon svg path:nth-child(2){
        opacity: 0;
      }
    }

    .content{
      .inner{
        @include flex(flex-start);
        padding: 1em 1.25em;

        @include responsive($tabletSmall, max){
          flex-direction: column; 
        }

        > p{
          @include responsive($tabletSmall){
            flex: 1;
            padding-top: 0.25em;
            padding-right: 1em;
          }
        }

        > .Btn{
          @include responsive($tabletSmall){
            margin-top: 0;
          } 
        }
      }
    }
  }

  // Cf. FAQ
  &:not(.--profile){
    &.--active{
      .head{
        background: $blue;
        color: $white;

        > .Icon.--chevron{
          transform: scaleY(-1);

          svg > *{
            stroke: $white;
          }
        }
      }
    }

    .head{
      font-weight: 600;
      background: $grey-l;
      border-radius: 8px;
    }
  }

  .head{
    @include flex(flex-start);
    padding: 0.8em 1.2em;
    width: 100%;
    transition: color 450ms $ease-in-out-circ,
                background 450ms $ease-in-out-circ;

    > span,
    > h3{
      flex: 1;
      padding-right: 1em;
    }

    > .Icon{
      margin-top: 0.5em;
      transition: transform 450ms $Power1EaseInOut;

      svg > *{
        transition: opacity 450ms $Power1EaseInOut, 
                    stroke 450ms $Power1EaseInOut;
      }
    }
  }

  .content{
    max-height: 0;
    overflow: hidden;
    transition: max-height 450ms $ease-in-out-circ;

    .inner{
      padding: 2em 1em;

      .Btn{
        margin-top: 1em;
      }
    }
  }
}
