.Copyright {
   @include flex(center, center);
   font: 400 14px $main-font;
   color: $white;

   @include responsive($tabletSmall){
     @include font-vw(14);
   }
   
   @include noTouchHover() {
      .wkn{
        transform: rotate(2deg) scale(0.95);
      }
   }

   .Footer &{
    @include responsive($tabletSmall){
      margin-left: auto;
    }
   }

   .wkn {
      margin-left: 0.5em;
      transition: transform 500ms $ease;

      svg > *{
        fill: $white;
      }
   }

   @include noTouchHover() {
     .wkn {
       opacity: 1;

       &+span {
         opacity: 1;
       }
     }
   }
}
