.RelatedQuestions{
  // Special styles for Landing Devis template
  .LandingDevis &{
    position: relative;
    z-index: 3;
    padding-top: 0;
    clip-path: none;
    margin-top: get-vw(-100);
    padding-bottom: 0;

    @include responsive($tabletSmall){
      padding-bottom: get-vw(100);
    }

    &:after{
      @include content();
      position: absolute;
      height: get-vw(102);
      width: get-vw(102);
      transform: scale(1.01);
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 10;

      bottom: 100%;
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 100C55.2285 100 100 55.2285 100 0V100H0Z' fill='%23F5F5F5'/%3E%3C/svg%3E%0A");
    }

    .container{
      padding-top: 60px;
      padding-bottom: 60px;
      background-color: #F5F5F5;
      clip-path: inset(0em 0em 0em round 5em 0em 0em);

      @include responsive($tabletSmall) {
        padding-top: get-vw(100);
        padding-bottom: get-vw(100);
      }
    }
  }

  .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  .container > * {
    width: 100%;
  }

  .container > .head{
    @include responsive($tabletSmall){
      max-width: space(12);
    }
  }

  .container > .questions{
    @include flex(flex-start);
    flex-direction: column;
    gap: 1em;

    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      max-width: space(26);
      margin-left: auto;
    }
  }
}
