.PackCard{
  @include flex(flex-start);
  gap: 1.5em;
  width: 100%;
  padding: 2em 0.5em;

  @include responsive($tabletSmall, max){
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  & + .PackCard{
    border-top: 1px solid rgba($green, 0.2);
  }

  > .visual__container{
    @include imgRatio(100, 100);
    max-width: 70px;

    @include responsive($tabletSmall){
      max-width: get-vw(70);
    }

    .visual{
      @include flex(center, center);
      transform: none;

      img{
        max-height: 100%;
        width: auto;
      }
    }
  }
  
  > .wrapper{
    @include responsive($tabletSmall){
      flex: 1;
      max-width: get-vw(430);
    }

    > span{
      font: 400 24px $main-font;

      @include responsive($tabletSmall){
        @include font-vw(36);
      }
    }
  }

  > .pricing{
    @include flex(center, center);
    flex-direction: column;
    gap: 0.5em;
    border-radius: 16px;
    background: $green;
    padding: 0.5em;
    height: get-vw(100);
    width: get-vw(100);
    color: $white;
    text-align: center;

    @include responsive($tabletSmall, max){
      min-width: 100px;
      min-height: 100px;
    }

    @include responsive($tabletSmall){
      margin-left: auto;
    }

    > span{
      font: 600 14px $main-font;

      @include responsive($tabletSmall){
        @include font-vw(16);
      }
    }

    > p{
      font: 600 14px $main-font;

      @include responsive($tabletSmall){
        @include font-vw(24);
      }
    }
  }
}
