.OfferCard{
  color: $white;
  background: $blue;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 3em;

  @include responsive($tabletSmall){
    padding: 3em 7em;
  }

  // Content
  > .wrapper{
    position: relative;
    z-index: 1;

    > span{
      font: 400 26px $main-font;
      margin-bottom: 0.5em;

      @include responsive($tabletSmall){
        @include font-vw(36);
      }
    }

    > .Btn{
      margin-top: 1em;
    }
  }

  // Subtitle
  > span{
    font: 700 23vw $main-font;
    text-transform: uppercase;
    opacity: 0.1;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

    @include responsive($tabletSmall){
      @include font-vw(200);
    }
  }

  // Icon
  > .Icon.--watermark-simple{
    @include cover();
    transform: scale(1.1);
    
    @include responsive($tabletSmall){
      transform: scale(1.5);
    }
  }

  > span, 
  > .Icon.--watermark-simple{
    z-index: 0;
  }
}
