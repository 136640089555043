.FormulaCard{
  @include flex(flex-start);
  color: $white;
  padding: 1.75em 2.5em 2em;

  @include responsive($tabletSmall, max){
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    gap: 2em;
  }

  .Icon.--formula{
    @include responsive($tabletSmall){
      min-width: get-vw(80);
    }
  }

  .wrapper{
    @include responsive($tabletSmall){
      margin-left: 3em;
    }

    > span{
      font: 700 22px $main-font;

      @include responsive($tabletSmall){
        @include font-vw(36);
      }
    }
  }
}
