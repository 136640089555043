.ProfileCard{
  @include noTouchHover() {
    .visual__container > .visual{
      transform: scale(1.1);
    }

    .Btn{
      background-color: $blue-d;
    }
  }

  .visual__container{
    @include imgRatio(290, 210);
    background: rgba($white, 0.15);
    border-radius: 26px 26px 0 0;

    > .visual{
      transform: none;
      transition: transform 450ms $Power2EaseInOut;
    }
  }

  .wrapper{
    margin-top: 1em;
    color: $white;

    > span{
      font: 600 20px $main-font;

      @include responsive($tabletSmall){
        @include font-vw(24);
      }
    }

    > .Btn{
      margin-top: 1em;
    }
  }
}
