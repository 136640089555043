.OurOffers{
  overflow: hidden;

  .container > .head{
    width: 100%;
    text-align: center;

    @include responsive($tabletSmall){
      max-width: space(28);
      margin: 0 auto;
    }
  }

  .container > .offers{
    margin-top: 1em;

    nav {
      &, ul{
        @include flex(center, center);
      }

      ul{
        background: $grey-alt;
        border-radius: 20em;
        padding: 0.25em;
        border: 1px solid rgba(#214C3E, 0.4);
      }

      button{
        font: 400 12px $main-font;
        border-radius: 20em;
        padding: 1em 2em;
        text-align: center;
        transition: color 650ms $ease,
                    background 650ms $ease;

        @include responsive($tabletSmall){
          @include font-vw(20);
          min-width: get-vw(350);
        }

        strong{
          font: inherit;
        }

        &.--active{
          font-weight: 600;
          color: $white;
          background: $blue;
        }
      }
    }

    .swiper{
      margin-top: 2.5em;

      &-slide{
        max-width: 90%;
        transition: opacity 450ms $Power2EaseInOut;

        @include responsive($tabletSmall){
          max-width: space(28);
        }

        &:not(.swiper-slide-active){
          opacity: 0.4;
        }
      }
    }
  }
}
