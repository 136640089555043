// Menu that contains everything
.NavContainer{
  pointer-events: all;

  body.--animating &{
    pointer-events: none;
  }

  @include responsive($menuBreakpoint, max) {
    @include flex(flex-start, center);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $base;
    color: $white;
    overflow-y: auto;
  }

  body:not(.--show-menu) & {
    @include responsive($menuBreakpoint, max) {
      display: none;
    }
  }

  > .inner {
    @include flex(flex-start, flex-start);
    flex-direction: column;
    gap: 40px;

    @include responsive($menuBreakpoint, max) {
      padding: 140px 40px 60px;
      width: 100%;
    }

    @include responsive($menuBreakpoint) {
      flex-direction: row;
      gap: get-vw(20);
      align-items: center;
    }
  }
}

.Nav{
  // Main navigation
  &.--main{
    @include responsive($menuBreakpoint) {
      margin-right: get-vw(10);
    }

    > ul {
      @include flex(center, flex-start);
      gap: 1em;
      list-style-type: none;
      margin: 0;
      padding: 0;

      @include responsive($menuBreakpoint, max){
        flex-direction: column;
      }

      > li{
        width: 100%;

        @include responsive($menuBreakpoint) {
          display: inline-block;
          width: auto;
          text-align: center;
        }
      }
    }
  }

  // Navigation with buttons only
  &.--buttons{    
    > ul{
      @include flex(flex-start, flex-start);
      position: relative;
      gap: 0.75em;
      z-index: 10;

      @include responsive($menuBreakpoint, max){
        flex-direction: column;
      }

      @include responsive($menuBreakpoint) {
        align-items: center;
      }
    }

    > ul li {
      &.--contact {
        @include responsive($menuBreakpoint) {
          position: absolute;
          bottom: 100%;
          right: 0;
          min-height: get-vw(50);
          margin-bottom: get-vw(10);
        }
      }
    }

    .Btn {
      position: relative;
      z-index: 10;
      padding: 1em 1.5em;

      &.--with-icon{
        padding: 0.7em 1em;
      }

      > span{
        font: 700 16px $main-font;

        @include responsive($tabletSmall) {
          @include font-vw(14);
        }
      }
    }
  }
}