.ChooseAIO{
  padding-top: 150px;
  background-color: #F5FAFA;
  clip-path: inset(1em round 1em);

  @include responsive($tabletSmall){
    padding-top: get-vw(150);
  }

  .container{
    @include flex(center, center);
    flex-direction: column;

    > *{
      width: 100%;
    }
  }

  .container > h2{
    text-align: center;

    @include responsive($tabletSmall){
      max-width: get-vw(500);
    }
  }

  .container > .items{
    @include flex(flex-start, center);
    flex-wrap: wrap;
    gap: 5em;
    margin-top: 2em;
    
    @include responsive($tabletSmall){
      padding: 0 6em;
      gap: 2em 4em;
    }

    .item{
      display: grid;
      justify-items: center;
      grid-gap: 0.25em;
      text-align: center;
      width: 100%;
      max-width: 300px;

      @include responsive($tabletSmall){
        max-width: calc(33.33333% - 4em);
      }

      > .icon{
        @include flex(center, center);
        background-color: #E7F9FA;
        border-radius: 10px;
        height: 80px;
        width: 80px;

        @include responsive($tabletSmall){
          height: get-vw(80);
          width: get-vw(80);
        }

        img{
          width: auto;
          max-width: 34px;
          height: auto;

          @include responsive($tabletSmall){
            max-width: get-vw(34);
          }
        }
      }

      > span{
        font-weight: 600;
        margin-top: 1.5em;
      }

      > p{
        
      }
    }
  }
}
