.Categories{
  ul{
    @include flex(center);
    flex-wrap: wrap;
    gap: 0.25em;

    > li{
      font: 700 12px $main-font;
      color: $red;
      background: $white;
      border: 1px solid $red;
      border-radius: 20em;
      padding: 1em;

      @include responsive($tabletSmall){
        @include font-vw(12);
      }
    }
  }
}
