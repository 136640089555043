.FooterNavBlock{
  &.--situation{
    @include responsive($tabletSmall){
      grid-area: situation;
    }
  }

  &.--health{
    @include responsive($tabletSmall){
      grid-area: health;
    }
  }

  &.--foresight{
    @include responsive($tabletSmall){
      grid-area: foresight;
    }
  }

  &.--employers{
    @include responsive($tabletSmall){
      grid-area: employers;
    }
  }  

  &.--learn-more{
    @include responsive($tabletSmall){
      grid-area: learn-more;
    }
  }

  &.--further{
    @include responsive($tabletSmall){
      grid-area: further;
    }
  }

  > span{
    font: 600 16px $main-font;
    margin-bottom: 1em;
  }

  > ul{
    list-style: initial;
    padding-left: 0.75em;

    li{
      line-height: 1;
      padding: 0.15em 0;

      .Link{
        font: 400 16px $main-font;
        line-height: 1;
      } 
    }
  }

  > span,
  > ul li .Link{
    @include responsive($tabletSmall){
      @include font-vw(16);
    }
  }
}
