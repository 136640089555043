.Sharing{
  &, > ul{
    @include flex(center, center);
    gap: 1em;
  }

  > span{
    color: $blue;
  }

  > ul{
    a{
      @include noTouchHover() {
        .ellipse{
          .Icon{
            transform: scale(0.9);
          }

          &:after{
            transform: translate(-50%, -50%) scale(1.2);
          }
        }
      }
    }

    a{
      .ellipse{
        position: relative;

        &:after,
        .Icon{
          transition: transform 250ms $SineEaseInOut;
        }

        .Icon{
          z-index: 1;
        }

        &:after{
          @include content();
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%) scale(0);
          height: 2em; width: 2em;
          background: $white;
          border-radius: 50%;
          z-index: 0;
        }
      }
    }
  }
}
