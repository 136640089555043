.FormTabs{
  @include flex(flex-start);
  flex-wrap: wrap;
  gap: 0.5em;
      
  @include responsive($tabletSmall){
    gap: 1em 0;
  }

  .AllPosts &{
    @include responsive($tabletSmall){
      padding-top: 0.5em;
      max-width: space(30);
    }
  }

  .AllQuestions &{
    @include responsive($tabletSmall){
      flex-direction: column;
      gap: 0.35em;
    }

    .tab{
      text-align: center;

      @include responsive($tabletSmall){
        width: 100%;
      }
    }
  }

  .tab{
    position: relative;
    cursor: pointer;

    input{
      @include cover();

      &:checked ~ span{
        font-weight: 700;
        color: $blue;

        @include responsive($tabletSmall, max){
          color: $white;
          background: $blue;
        }

        &:after{
          @include responsive($tabletSmall){
            transform: scaleX(1);
          }
        }
      }
    }
      
    span{
      display: block;
      position: relative;
      color: $green;
      padding: 1em 2em;
      transition: font-weight 450ms $Power3EaseInOut,
                  color 450ms $Power3EaseInOut;        
          
      @include responsive($tabletSmall, max){
        background: $grey-l;
      }

      &:before,
      &:after{
        @include responsive($tabletSmall){
          @include content();
          position: absolute;
          bottom: 0; left: 0;
          width: 100%;
        }
      }

      &:before{
        @include responsive($tabletSmall){
          height: 1px;
          background: $grey-l1;
        }
      }

      &:after{
        @include responsive($tabletSmall){
          height: 2px;
          background: $blue;
          transform: scaleX(0);
          transition: transform 450ms $Power1EaseInOut;
        }
      }
    }
  }
}