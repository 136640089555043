h1, h2, h3, h4, h5, h6{
   font-weight: 500;
}

h1 {
   font: 300 40px $main-font;
   letter-spacing: -0.02em;
   line-height: 1.15;
   margin-bottom: 0.5em;

   @include responsive($tabletSmall){
     @include font-vw(64);
   }
}

h2 {
   font: 300 30px $main-font;
   line-height: 1.2;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(48);
   }
}

h3 {
   font: 300 28px $main-font;

   @include responsive($tabletSmall){
     @include font-vw(36);
   }
}

h4 {
   font: 300 26px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(22);
   }
}

h5 {
   font: 300 24px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(20);
   }
}

h6 {
   font: 300 22px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(18);
   }
}

a {
   &.download {
      color: $blue;
      font-weight: 600;
      margin-top: 45px;
      display: flex;
      max-width: max-content;

      @include responsive($tabletSmall) {
         @include font-vw(16);
      }

      &::before {
         content: '';
         background: url('../imgs/download.svg');
         background-size: contain;
         background-repeat: no-repeat;
         width: 21.5px;
         height: 21.5px;
         margin-right: 8px;

         @include responsive($tabletSmall) {
            width: get-vw(21.5);
            height: get-vw(21.5);
         }
      }
   }
}

strong {
   font-weight: 600;
}

time{
   font: italic 400 12px $main-font;
   color: $green;

   @include responsive($tabletSmall){
     @include font-vw(12);
   }
}

.small{
   font: 400 16px $main-font;
   line-height: 1.25;

   @include responsive($tabletSmall){
     @include font-vw(16);
   }
}

.wswyg--content {
   * { color: inherit }

   > * + * {
      margin-top: 1.25em;
   }

   ul,
   ol {
      li ul{
         list-style: circle;
      }

      li + li {
         margin-top: 0.5em;
      }

      code {
         margin-top: 1em;
         margin-bottom: 1em;
      }
   }

   ul{
      padding-left: 1em;
      list-style: disc;
   }

   ol {
      padding-left: 1.25em;
      list-style-type: decimal;
   }

   figure {
      margin: 1.5em auto;
      overflow: hidden;

      @include responsive($tabletSmall){
         margin: space(1) auto;
      }

      &:last-child {
         margin-bottom: 0px;
      }

      img {
         display: block;
      }
   }

   blockquote {
      padding-left: 3em;
      border-left: 2px solid rgba($green, 0.35);

      @include responsive($tabletSmall){
         padding-left: space(0.5);
      }

      p{
         font: 500 24px $main-font;
         line-height: 1.4;
         
         @include responsive($tabletSmall){
            @include font-vw(32);
         }
      }
   }

   a {
      color: $blue;
      font-weight: bold;
      text-decoration: underline;
   }

   p {
      line-height: 1.3;
   }

   strong {
      font-weight: 600;
   }

   small{
      font-size: 0.8em;
      opacity: 0.6;
   }

   em{
      font-weight: inherit;
      font-style: italic;
   }

   img{
      border-radius: 30px;
      width: 100%;
      height: auto;
   }

   .plyr__container{
      aspect-ratio: 16/9;
      border-radius: 25px;
      margin: 3.5em 0 0;

      .plyr__control--overlaid {
         background: $blue;

         svg{
            fill: $white;
         }
      }
   }

   table{
      thead{
         color: $blue;
      }

      td{
         border: 1px solid #c7c7c7;
         padding: 1em 0.5em;
      }
   }
}
