.ParrainageForm {
    padding-top: 8.5em;

    @include responsive($tabletSmall) {
        padding-top: 12.5em;
    }

    > .container {
        @include responsive($tabletSmall) {
            padding: 0;
            gap: get-vw(40);
            @include flex(flex-start, space-between);
        }
    }

    &__text {
        flex-basis: 50%;
        padding: 0 20px;

        @include responsive($tabletSmall) {
            padding: 0 get-vw(80);
        }

        .card {
            background: $blue;
            padding: 40px 50px;
            border-radius: 30px;
            margin: 30px 0;
            max-width: 465px;

            @include responsive($tabletSmall) {
                padding: get-vw(40) get-vw(50);
                max-width: get-vw(465);
            }

            &:last-child {
                margin-bottom: 60px;
            }

            span {
                color: white;
                font-size: 26px;
                font-weight: 500;
                line-height: 1.1;

                @include responsive($tabletSmall) {
                    @include font-vw(36);
                }
            }

            .number {
                font-size: 50px;
                font-weight: 600;
                line-height: 0.7;
                letter-spacing: -1.28px;

                @include responsive($tabletSmall) {
                    @include font-vw(64);
                }
            }

            p {
                margin-bottom: 16px;
                font-weight: 600;
                font-size: 20px;

                @include responsive($tabletSmall) {
                  @include font-vw(24);
                }
            }
        }

        .Link{
            margin-top: 2em;
        }
    }

    .Form {
        flex-basis: 50%;

        @include responsive($tabletSmall, max) {
          margin: 40px -30px 0px -30px;
        }

        h3 {
            font-size: 24px;

            @include responsive($tabletSmall) {
              @include font-vw(24);
            }

            &:not(:first-child) {
                margin: 30px 0 35px;
    
                @include responsive($tabletSmall) {
                    margin: get-vw(30) 0 get-vw(35);
                }
            }
        }

        .Form__group {
            &.--tel {
                flex-basis: calc(50% - 17.5px);
            }
        }
    }
}