.PresentationCard{
  background: $grey-alt;
  height: 100%;
  padding: 3em;

  > span{
    font: 300 24px $main-font;
    line-height: 1.1;
    color: $blue;
    margin-bottom: 0.75em;

     @include responsive($tabletSmall){
       @include font-vw(36);
     }
  }

  > .Btn{
    margin-top: 1.25em;
  }
}