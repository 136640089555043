.Anchors {
  &__nav {
    @include flex();
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: -10px;
  }

  &__item {
    margin-right: 20px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}