.Footer {
   position: relative;
   z-index: 10;
   margin-top: get-vw(-100);

   &:after{
      bottom: 100%; right: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 100C55.2285 100 100 55.2285 100 0V100H0Z' fill='%23214C3E'/%3E%3C/svg%3E%0A");
   }

   > .container{
      background: $base;
      color: $white;
      padding-bottom: get-vw(50);
   }

   > .container > .head,
   > .container > .sub{
      @include flex(flex-start);

      @include responsive($tabletSmall, max) {
         flex-direction: column;
         gap: 2em;
      }
   }

   .container  > .head {
      .main-nav {
         display: grid;
         grid-gap: 2em;

         @include responsive($mobile) {
            grid-template-columns: repeat(2, 1fr);
         }

         @include responsive($tabletSmall) {
            max-width: space(36);
            margin-left: auto;
            grid-template-columns: none;
            grid-template-areas:
               'situation health employers learn-more'
               'situation foresight employers further';
         }
      }
   }

   .container  > .sub{
      margin-top: 5em;

      .container{
         @include responsive($tabletSmall){
            align-items: center;
         }
      }
   }
}
