.SimulatorDevis{
  position: relative;
  z-index: 10;

  @include responsive($tabletSmall){
    padding-bottom: get-vw(70);
  }

  // For parent page
  &.--parent{
    .container > .panel{
      > .inner{
        .title{
          font-size: 30px;

          @include responsive($tabletSmall){
            @include font-vw(36);
          }
        }
      }
    }
  }

  // For children pages
  &:not(.--parent){

  }

  .container > .panel{
    @include flex(center, flex-start);
    flex-direction: column;
    padding: 3.75em 2.5em 2.75em;
    width: 100%;
    border-radius: 20px;
    background-color: $white;
    box-shadow: 0px 44px 74px rgba($black, 0.05);
    border: 1px solid rgba($green, 0.1);
      
    @include responsive($tabletSmall){
      position: absolute;
      top: -100%; right: space(4);
      max-width: get-vw(570);
    }

    > .SimulatorMacaron{
      position: absolute;
      top: 0; left: 0;
      transform: translate(0%, -70%) rotate(-4deg);
      
      @include responsive($tabletSmall){
        transform: translate(-50%, -50%) rotate(-4deg);
      }
    }

    > .inner{
      width: 100%;

      .title{
        font-size: 24px;
        display: block;
        text-align: center;

        @include responsive($tabletSmall){
          @include font-vw(24);
        }
      }

      nav.items{
        margin-top: 1.5em;

        &, ul{
          @include flex(center, center);
          flex-direction: column;
          gap: 1em;
        }

        ul, li{
          width: 100%;
        }
      }

      .back{
        font: 600 16px $main-font;
        border: 1px solid rgba($green, 0.1);
        border-radius: 20em;
        display: inline-flex;
        align-items: center;
        gap: 1em;
        padding: 1.25em 2.5em 1.25em 1.875em;
        margin-top: 1.25em;
        transition: transform 500ms $ease;

        @include noTouchHover() {
          transform: scale(0.95);
        }

        .Icon.--arrow svg > *{
          fill: $green;
        }
      }
    }
  }

  .container > .wswyg--content{
    @include responsive($tabletSmall){
      max-width: get-vw(480);
      margin-left: space(2);
    }
  }
}
