.Packs{
  padding-bottom: 0;

  &.--novaio{
    .OfferDetails[data-active-offer="2"] &{
      display: none;
    }
  }

  &.--moderato{
    .OfferDetails[data-active-offer="1"] &{
      display: none;
    }
  }

  .container {
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
      gap: 2.5em;
    }

    > * {
      width: 100%;
    }
  }

  .container > .head{
    @include responsive($tabletSmall, max){
      text-align: center;
    }

    @include responsive($tabletSmall){
      max-width: space(17);
    }

    h2{
      margin-bottom: 0.5em;
    }
  }

  .container > .grid{
    @include responsive($tabletSmall){
      max-width: space(24);
      margin-left: auto;
    }
  }
}
