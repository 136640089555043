.TestimonialsAlternative{
  overflow: hidden;
  padding-bottom: 120px;

  @include responsive($tabletSmall){
    padding-bottom: get-vw(120);
  }

  .container{
    @include flex(flex-start);
    gap: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > * {
      width: 100%;
    }
  }

  .head{
    @include flex(center, center);
    flex-direction: column;

    @include responsive($tabletSmall){
      align-items: flex-start;
      justify-content: flex-start;
      max-width: getv-w(450);
    }

    h2{
      margin-bottom: 0.75em;
    }

    .swiper-navigation{
      justify-content: flex-start;
    }
  }

  .swiper{
    overflow: hidden;

    @include responsive($tabletSmall){
      margin-left: auto;
      max-width: get-vw(720);
    }

    .swiper-slide{
      blockquote{
        cite{
          @include flex(center, center);

          .visual__container{
            @include imgRatio(100, 100);
            border-radius: 50%;
            max-width: 100px;

            @include responsive($tabletSmall){
              max-width: get-vw(100);
            }
          }
        }
      }
    }
  }
}
